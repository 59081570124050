import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';
export const OfferListingFragmentDoc = `
    fragment OfferListing on StaticOfferOptions {
  attributes(sort: {order: asc, by: name}) {
    id
    name
    total
  }
  brands(sort: {order: asc, by: name}) {
    name
    brandCode
  }
  offers {
    headline
    shortDescription
    campaignId
    id
    name
    brands
    attributes {
      name
      id
    }
    externalLink {
      url
      label
      isNewWindow
    }
    images(imageVariant: offerListing) {
      ogImage: url(width: 1200, height: 630)
      altText
      variants {
        url
        size
      }
    }
    bookEndFmt
    stayEndFmt
  }
}
    `;
export const StaticOfferFragmentDoc = `
    fragment StaticOffer on StaticOffer {
  id
  campaignId
  ctyhocns(filter: {value: $ctyhocn})
  headline
  cost
  shortDescription
  longDescription
  keySellingPoint
  name
  honors
  srpBrands {
    srpCode
    brands
  }
  images(imageVariant: offerDetail) {
    altText
    ogImage: url(width: 1200, height: 630)
    variants {
      url
      size
    }
  }
  attributes {
    name
    id
  }
  policy {
    name
    terms {
      components {
        id
        index
      }
      orderedList {
        id
        value
        __typename
      }
      unorderedList {
        id
        value
        __typename
      }
      heading {
        id
        value
        __typename
      }
      description {
        id
        value
        __typename
      }
    }
  }
  externalLink {
    adaDescription
    isNewWindow
    label
    url
  }
  seo {
    hideSearchEngine
    metaDescription
    metaKeywords
    pageTitle
  }
  type
  bookStartFmt
  a11yBookStartFmt: bookStartFmt(format: "long")
  bookEndFmt
  a11yBookEndFmt: bookEndFmt(format: "long")
  stayStartFmt
  a11yStayStartFmt: stayStartFmt(format: "long")
  stayStart
  stayEndFmt
  a11yStayEndFmt: stayEndFmt(format: "long")
}
    `;
export const BrandOfferListingDocument = {
  operationName: 'brand_brandStaticOfferOptions',
  operationString: `query brand_brandStaticOfferOptions($language: String!, $brandCode: String!) {
  brand(brandCode: $brandCode, language: $language) {
    name
    formalName
    code
    page(path: "offers") {
      seo {
        hideSearchEngine
        metaDescription
        metaKeywords
        pageTitle
      }
    }
  }
  brandStaticOfferOptions(brandCode: $brandCode, language: $language) {
    ...OfferListing
  }
}

      ${OfferListingFragmentDoc}`,
  originalOpName: 'brandOfferListing',
};
export function useBrandOfferListingQuery<TData = Types.BrandOfferListingQuery, TError = DXError>(
  variables: Types.BrandOfferListingQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandOfferListingQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandOfferListingQuery, TError, TData>({
    queryKey: [BrandOfferListingDocument, variables],
    ...options,
  });
}
export function serverSideBrandOfferListingQuery(
  queryClient: QueryClient,
  variables: Types.BrandOfferListingQueryVariables,
  options?: FetchQueryOptions<Types.BrandOfferListingQuery>
) {
  return queryClient.fetchQuery<Types.BrandOfferListingQuery>({
    queryKey: [BrandOfferListingDocument, variables],
    ...options,
  });
}

export const HotelOfferListingDocument = {
  operationName: 'brand_hotel_hotelStaticOfferOptions',
  operationString: `query brand_hotel_hotelStaticOfferOptions($language: String!, $ctyhocn: String!) {
  hotel(language: $language, ctyhocn: $ctyhocn) {
    name
    ctyhocn
    brand {
      name
      canonicalSlug
      altSlugs
      formalName
      code
      page(path: "offers") {
        seo {
          hideSearchEngine
          metaDescription
          metaKeywords
          pageTitle
        }
      }
    }
  }
  hotelStaticOfferOptions(language: $language, ctyhocn: $ctyhocn) {
    ...OfferListing
  }
}

      ${OfferListingFragmentDoc}`,
  originalOpName: 'hotelOfferListing',
};
export function useHotelOfferListingQuery<TData = Types.HotelOfferListingQuery, TError = DXError>(
  variables: Types.HotelOfferListingQueryVariables,
  options?: Omit<UseQueryOptions<Types.HotelOfferListingQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HotelOfferListingQuery, TError, TData>({
    queryKey: [HotelOfferListingDocument, variables],
    ...options,
  });
}
export function serverSideHotelOfferListingQuery(
  queryClient: QueryClient,
  variables: Types.HotelOfferListingQueryVariables,
  options?: FetchQueryOptions<Types.HotelOfferListingQuery>
) {
  return queryClient.fetchQuery<Types.HotelOfferListingQuery>({
    queryKey: [HotelOfferListingDocument, variables],
    ...options,
  });
}

export const StaticOfferDocument = {
  operationName: 'brand_hotel_staticOffer',
  operationString: `query brand_hotel_staticOffer($language: String!, $offerId: BigInt!, $includeHotel: Boolean!, $ctyhocn: String!, $brandCode: String!) {
  hotel(language: $language, ctyhocn: $ctyhocn) @include(if: $includeHotel) {
    name
    ctyhocn
    brand {
      name
      code
    }
  }
  brand(brandCode: $brandCode, language: $language) {
    name
    formalName
    canonicalSlug
    altSlugs
  }
  staticOffer(language: $language, offerId: $offerId) {
    ...StaticOffer
  }
}

      ${StaticOfferFragmentDoc}`,
  originalOpName: 'staticOffer',
};
export function useStaticOfferQuery<TData = Types.StaticOfferQuery, TError = DXError>(
  variables: Types.StaticOfferQueryVariables,
  options?: Omit<UseQueryOptions<Types.StaticOfferQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.StaticOfferQuery, TError, TData>({
    queryKey: [StaticOfferDocument, variables],
    ...options,
  });
}
export function serverSideStaticOfferQuery(
  queryClient: QueryClient,
  variables: Types.StaticOfferQueryVariables,
  options?: FetchQueryOptions<Types.StaticOfferQuery>
) {
  return queryClient.fetchQuery<Types.StaticOfferQuery>({
    queryKey: [StaticOfferDocument, variables],
    ...options,
  });
}
