import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';
import { Popup } from '@dx-ui/osc-popup';
import NavItemWithModal from './nav-item-with-modal';
import NavItemWithPopup from './nav-item-with-popup';
import Icon from '@dx-ui/osc-icon';
import { LoginIFrame } from '@dx-ui/osc-login';
import NavItemWithLink from './nav-item-with-link';
import type { HeaderProps, MegaMenuProps } from '../types';
import {
  sendInteractionReward,
  trackNavClickBasedOnLabel,
  trackNavClick,
} from '../header.utilities';
import { LanguageSelector } from '@dx-ui/osc-language-selector';
import { sendReward } from '@dx-ui/framework-conductrics';
import { Logo } from '../header.logo';
import { useRef } from 'react';
import { getLocaleFormattedNumber } from '@dx-ui/framework-i18n';
import { useRouter } from 'next/router';

const MegaUserLink = ({
  url,
  onClick,
  children,
  isNewWindow,
}: {
  url: string;
  onClick: () => void;
  children: React.ReactNode;
  isNewWindow?: boolean;
}) => {
  return (
    <MenuListItem>
      <Link
        url={url}
        underline={false}
        className="whitespace-nowrap text-sm"
        anchorClassName="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
        onClick={onClick}
        isNewWindow={isNewWindow}
      >
        {children}
      </Link>
    </MenuListItem>
  );
};

export function MegaMenu({
  isFluid,
  suppressLogo = false,
  shouldSuppressHonorsAccountLinks,
  shouldSuppressFindStay,
  shouldSuppressJoinAndSignIn,
  shouldOpenAccountLinksInNewWindow,
  brand,
  mainNavLinks,
  user,
  languageSelectorOptions,
  loginOptions,
  onSignInAttempt,
  userLinks,
  onSignOut,
  heading,
  theme,
  SignUpComponent,
}: MegaMenuProps) {
  const userNavProps = {
    user,
    userLinks,
    onSignInAttempt,
    onSignOut,
    loginOptions,
    languageSelectorOptions,
    theme,
    shouldSuppressHonorsAccountLinks,
    shouldSuppressFindStay,
    shouldSuppressJoinAndSignIn,
    shouldOpenAccountLinksInNewWindow,
    SignUpComponent,
  };
  const isDark = theme === 'dark';
  const headingLevelProps = heading
    ? {
        role: 'heading',
        'aria-level': 1,
        'aria-label': heading,
      }
    : {};

  return (
    <div className="bg-transparent">
      <div
        className={cx({
          'container-fluid': isFluid,
          container: !isFluid,
        })}
      >
        <div className="relative flex flex-wrap">
          {suppressLogo ? null : (
            <div className="flex min-h-[4.5rem] items-center" {...headingLevelProps}>
              <Link
                url={brand.url}
                target={brand.target}
                onClick={sendInteractionReward}
                // once nav is implemented on all pages for OHW make w-72 for all brands per UX/UI
                anchorClassName="relative block h-14 w-28 brand-ou:lg:w-72 brand-lx:lg:w-72 me-1"
                showNewWindowIcon={false}
              >
                <Logo theme={theme} brand={brand} />
              </Link>
            </div>
          )}
          <div className="absolute end-0 top-0">
            <UserNav {...userNavProps} />
          </div>
        </div>
      </div>
      <div
        className={cx({
          'nav-bottom-link-area': !isDark,
          'nav-bottom-link-area-dark': isDark,
        })}
      >
        <div
          className={cx({
            'container-fluid': isFluid,
            container: !isFluid,
          })}
        >
          <div className="flex overflow-auto" data-testid="header-links-container">
            {mainNavLinks?.length ? <MainNav theme={theme} mainNavLinks={mainNavLinks} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

type UserNavProps = Pick<
  MegaMenuProps,
  | 'loginOptions'
  | 'onSignInAttempt'
  | 'onSignOut'
  | 'user'
  | 'userLinks'
  | 'languageSelectorOptions'
  | 'theme'
  | 'shouldSuppressHonorsAccountLinks'
  | 'shouldSuppressFindStay'
  | 'shouldSuppressJoinAndSignIn'
  | 'shouldOpenAccountLinksInNewWindow'
  | 'SignUpComponent'
>;

function UserNav({
  loginOptions,
  onSignInAttempt,
  onSignOut,
  user,
  languageSelectorOptions,
  userLinks,
  theme,
  shouldSuppressHonorsAccountLinks,
  shouldSuppressFindStay,
  shouldSuppressJoinAndSignIn,
  shouldOpenAccountLinksInNewWindow,
  SignUpComponent,
}: UserNavProps) {
  const { locale = 'en' } = useRouter();
  const [t] = useTranslation('osc-header');

  const defaultOptions: React.ComponentProps<typeof LoginIFrame> = {
    frameSrc: userLinks?.signInLink?.url || '',
    title: t('signIn'),
    onLoginAttempt: onSignInAttempt,
  };

  const userButtonLinkRef = useRef<HTMLButtonElement>(null);

  const isDark = theme === 'dark';
  const formattedHonorsPointsTotal = getLocaleFormattedNumber(user?.honorsPoints, { locale });

  return (
    <nav className="relative ms-auto flex min-h-[4.5rem]" aria-label={t('hiltonHonors')}>
      <ul className="flex">
        {languageSelectorOptions ? (
          <li className="flex items-center pb-2 pe-2 pt-1">
            <LanguageSelector theme={theme} {...languageSelectorOptions} />
          </li>
        ) : null}
        {userLinks?.findStayLink && !shouldSuppressFindStay ? (
          <NavItemWithLink
            onClick={() => {
              sendInteractionReward();
              sendReward('find-stay-click');
              trackNavClick('gh_findstay');
            }}
            url={userLinks?.findStayLink.url}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
            showNewWindowIcon={false}
            className="flex"
            theme={theme}
          >
            <span className="nav-item-text self-center">{t('findStay')}</span>
            <Icon
              name="calendar"
              className={cx('ms-2 group-focus-within:hidden group-hover:hidden', {
                'nav-icon': !theme,
                'nav-icon-dark': isDark,
              })}
            />
            <Icon
              name="calendar"
              variant="solid"
              className={cx('ms-2 hidden group-focus-within:block group-hover:block', {
                'nav-icon': !theme,
                'nav-icon-active-dark': isDark,
              })}
            />
          </NavItemWithLink>
        ) : null}
        {user ? (
          <Popup>
            <NavItemWithPopup
              theme={theme}
              popupButtonRef={userButtonLinkRef}
              onClick={() => {
                trackNavClick('gh_membername');
                sendReward('profile-name-click');
              }}
              buttonChildren={
                <>
                  <span className="nav-item-text whitespace-nowrap text-sm">
                    {t('greetings', { username: user.name })}
                  </span>
                  <Icon
                    name="user-circle"
                    className={cx('ms-2 group-focus-within:hidden group-hover:hidden', {
                      'nav-icon': !theme,
                      'nav-icon-dark': isDark,
                    })}
                  />
                  <Icon
                    name="user-circle"
                    variant="solid"
                    className={cx('ms-2 hidden group-focus-within:block group-hover:block', {
                      'nav-icon': !theme,
                      'nav-icon-active-dark': isDark,
                    })}
                  />
                </>
              }
            >
              <div className="container flex py-5">
                <div className="flex items-center">
                  <div className="bg-hilton-alt flex size-20 shrink-0 items-center justify-center rounded-full">
                    <Icon name="user" size="4xl" />
                  </div>
                  <div className="ms-5">
                    <p className="brand-ey:font-normal mb-1 text-sm font-bold">
                      {t('greetings', { username: user.name })}
                    </p>
                    <p className="mb-1 capitalize">
                      <span className="brand-ey:font-normal text-sm font-bold underline">
                        {user.honorsTier}
                      </span>{' '}
                      {t('status')}
                    </p>
                    <p className="brand-ey:font-normal mb-1 text-sm">
                      {t('honorsPointsTotal', { honorsPointsTotal: formattedHonorsPointsTotal })}
                    </p>
                    {user.hhonorsNumber ? (
                      <p className="brand-ey:font-normal mb-1 text-sm">
                        {t('hiltonHonorsNumber', { hhonorsNumber: user.hhonorsNumber })}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="ms-5 flex-1 xl:ms-10">
                  <MenuList>
                    {userLinks?.accountLink ? (
                      <MegaUserLink
                        url={userLinks?.accountLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_hiltonhonorshome');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('hiltonHonorsHome')}
                      </MegaUserLink>
                    ) : null}
                    {userLinks?.honorsActivityLink && !shouldSuppressHonorsAccountLinks ? (
                      <MegaUserLink
                        url={userLinks?.honorsActivityLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_hiltonhonorsactivity');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('activity')}
                      </MegaUserLink>
                    ) : null}

                    {userLinks?.honorsPointsLink?.url && !shouldSuppressHonorsAccountLinks ? (
                      <MegaUserLink
                        url={userLinks.honorsPointsLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_hiltonhonorspoints');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('pointsLabel')}
                      </MegaUserLink>
                    ) : null}

                    {userLinks?.honorsProfileLink?.url && !shouldSuppressHonorsAccountLinks ? (
                      <MegaUserLink
                        url={userLinks.honorsProfileLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_hiltonhonorsprofile');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('profile')}
                      </MegaUserLink>
                    ) : null}

                    {userLinks?.goHiltonLink?.url ? (
                      <MegaUserLink
                        url={userLinks.goHiltonLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_gohiltonprogramdetails');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('goHiltonProgramDetails')}
                      </MegaUserLink>
                    ) : null}

                    {userLinks?.friendsAndFamilyLink?.url ? (
                      <MegaUserLink
                        url={userLinks.friendsAndFamilyLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_managefriendsandfamily');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('manageFriendsAndFamily')}
                      </MegaUserLink>
                    ) : null}

                    {userLinks?.hgvMaxLink?.url ? (
                      <MegaUserLink
                        url={userLinks.hgvMaxLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_hgvmaxratedetails');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('hgvMaxRateDetails')}
                      </MegaUserLink>
                    ) : null}

                    {userLinks?.smbMemberLink?.url ? (
                      <MegaUserLink
                        url={userLinks.smbMemberLink.url}
                        onClick={() => {
                          trackNavClick('gh_membername_managehiltonforbusiness');
                        }}
                        isNewWindow={shouldOpenAccountLinksInNewWindow}
                      >
                        {t('manageHiltonForBusiness')}
                      </MegaUserLink>
                    ) : null}
                    <MenuListItem>
                      <button
                        className="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light w-full whitespace-nowrap p-3 text-left"
                        onClick={async () => {
                          trackNavClick('gh_membername_signout');
                          await onSignOut();
                        }}
                        type="button"
                      >
                        {t('signOut')}
                      </button>
                    </MenuListItem>
                  </MenuList>
                </div>
              </div>
            </NavItemWithPopup>
          </Popup>
        ) : (userLinks?.signInLink || userLinks?.signUpLink || SignUpComponent) &&
          !shouldSuppressJoinAndSignIn ? (
          <>
            {userLinks?.signUpLink || SignUpComponent ? (
              <>
                <NavListItem className="border-e-text px-1" theme={theme}>
                  <div className="flex h-full items-center pt-1 [&_a]:flex [&_a]:h-full [&_a]:items-center [&_button]:flex [&_button]:h-full [&_button]:items-center">
                    {SignUpComponent ? (
                      <SignUpComponent
                        loggedInButtonRef={userButtonLinkRef}
                        className="nav-item-text mx-1"
                      >
                        {t('signUp')}
                      </SignUpComponent>
                    ) : userLinks?.signUpLink ? (
                      <Link
                        {...userLinks.signUpLink}
                        className="nav-item-text mx-1"
                        underline={false}
                        showNewWindowIcon={false}
                        onClick={() => {
                          sendInteractionReward();
                          sendReward('nav-join-click');
                          trackNavClick('gh_join_signin');
                        }}
                      >
                        {t('signUp')}
                      </Link>
                    ) : null}
                  </div>
                </NavListItem>
                <li
                  aria-hidden={true}
                  className={cx(
                    'divider-padding inline-flex h-full items-center text-sm font-bold',
                    { 'text-text-inverse': isDark }
                  )}
                >
                  |
                </li>
              </>
            ) : null}
            {userLinks?.signInLink ? (
              <NavListItem theme={theme}>
                <NavItemWithModal
                  theme={theme}
                  onLoginAttempt={defaultOptions.onLoginAttempt}
                  title={defaultOptions.title}
                  onClick={() => {
                    trackNavClick('gh_join_signin');
                    sendReward('nav-sign-in-click');
                  }}
                  buttonChildren={
                    <span className="flex h-full items-center pt-1">
                      <span className="nav-item-text items-center">{t('signIn')}</span>
                      <Icon
                        name="user-circle"
                        className={cx('ms-2 group-focus-within:hidden group-hover:hidden', {
                          'nav-icon': !theme,
                          'nav-icon-dark': isDark,
                        })}
                        wrapper="span"
                      />
                      <Icon
                        name="user-circle"
                        variant="solid"
                        className={cx('ms-2 hidden group-focus-within:block group-hover:block', {
                          'nav-icon': !theme,
                          'nav-icon-active-dark': isDark,
                        })}
                        wrapper="span"
                      />
                    </span>
                  }
                >
                  <LoginIFrame data-e2e="loginIframe" {...defaultOptions} {...loginOptions} />
                </NavItemWithModal>
              </NavListItem>
            ) : null}
          </>
        ) : null}
      </ul>
    </nav>
  );
}

type MainNavProps = Required<Pick<MegaMenuProps, 'mainNavLinks'>> & {
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
};

function MainNav({ mainNavLinks, theme }: MainNavProps) {
  return (
    <div className="flex min-h-[4.5rem] items-center">
      <div className="size-full overflow-x-visible">
        <ul className="flex h-full items-center rtl:space-x-reverse">
          {mainNavLinks.map(({ label, subMenu, link, onClick }) => {
            if (!subMenu?.length && link?.url) {
              return (
                <NavItemWithLink
                  {...link}
                  className="nav-main-link"
                  key={label}
                  theme={theme}
                  onClick={() => {
                    sendInteractionReward();
                    trackNavClickBasedOnLabel(label);
                    onClick?.();
                  }}
                >
                  {label}
                </NavItemWithLink>
              );
            }
            if (subMenu?.length) {
              return (
                <Popup key={label}>
                  <NavItemWithPopup
                    theme={theme}
                    onClick={() => {
                      trackNavClickBasedOnLabel(label);
                    }}
                    expandedButtonChildren={
                      <>
                        <span className="nav-main-link whitespace-nowrap">{label}</span>
                        <Icon name="arrowhead-small-up" />
                      </>
                    }
                    buttonChildren={
                      <>
                        <span className="nav-main-link whitespace-nowrap">{label}</span>
                        <Icon className="rotate-180" name="arrowhead-small-up" />
                      </>
                    }
                  >
                    <div className="container flex-1 py-5">
                      <MenuList>
                        {subMenu.map(({ label: subMenuLabel, link, onClick }) => (
                          <MenuListItem key={subMenuLabel}>
                            <Link
                              {...link}
                              underline={false}
                              className="nav-drawer-link-text"
                              anchorClassName="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:font-normal brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
                              onClick={() => {
                                trackNavClickBasedOnLabel(subMenuLabel, label);
                                onClick?.();
                              }}
                            >
                              {subMenuLabel}
                            </Link>
                          </MenuListItem>
                        ))}
                      </MenuList>
                    </div>
                  </NavItemWithPopup>
                </Popup>
              );
            }
            return null;
          })}
        </ul>
      </div>
    </div>
  );
}

function MenuList({ children, ...listProps }: React.ComponentProps<'ul'>) {
  return (
    <ul
      {...listProps}
      className={cx('columns-3 gap-6 [&_li]:break-inside-avoid [&_li]:py-3', listProps.className)}
    >
      {children}
    </ul>
  );
}

function MenuListItem({ children, ...listItemProps }: React.ComponentProps<'li'>) {
  return (
    <li {...listItemProps}>
      <span className="border-border brand-ey:hover:font-normal brand-ey:hover:bg-tertiary-alt brand-ey:hover:border-b-primary-alt brand-ou:border-primary brand-ou:hover:bg-bg-light block border-b-2 hover:font-bold [&_a]:flex [&_a]:p-3">
        {children}
      </span>
    </li>
  );
}

function NavListItem({
  children,
  theme,
  ...listItemProps
}: React.ComponentProps<'li'> & Pick<HeaderProps, 'theme'>) {
  const isDark = theme === 'dark';
  return (
    <li
      {...listItemProps}
      className={cx(
        'flex items-center',
        {
          'nav-list-item': !theme,
          'nav-list-item-dark': isDark,
        },
        listItemProps.className
      )}
    >
      {children}
    </li>
  );
}

export default MegaMenu;
