import { useAuth } from '@dx-ui/framework-auth-provider';
import { Header } from '@dx-ui/osc-header';
import { useTranslation } from 'next-i18next';
import { useNavigationQuery } from '../generated/react-query';
import { DX_AUTH_UI, OHW_BASE_URL_TRIMMED } from '../utils/env-vars';
import type { Link } from '@dx-ui/osc-link';

export function OffersHeader({
  brandCode = 'WW',
  brandName = 'Hilton',
  brandUrl = '',
  language = 'en',
}) {
  const { login, guestInfo, logout } = useAuth();
  const { data } = useNavigationQuery({ brandCode, language: language || 'en' });
  const { t } = useTranslation('common');
  return (
    <Header
      mainNavLinks={data?.brand?.header?.sections?.[0]?.links as Link[]}
      user={
        guestInfo
          ? {
              name: guestInfo.personalinfo?.name?.firstName,
              honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
              honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
              honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
            }
          : undefined
      }
      onSignOut={async () => {
        await logout();
      }}
      onSignInAttempt={async ({ data }) => {
        if (data) {
          await login({ data });
        }
      }}
      brand={{
        url: brandUrl || 'https://www.hilton.com',
        code: brandCode,
        name: brandCode === 'WW' ? t('forTheStay') : brandName,
      }}
      userLinks={{
        signInLink: {
          url: DX_AUTH_UI?.replace('__LANG__', language),
        },
        accountLink: {
          url: `${OHW_BASE_URL_TRIMMED}/${language}/hilton-honors/guest/my-account/`,
        },
        signUpLink: {
          url: `${OHW_BASE_URL_TRIMMED}/${language}/hilton-honors/join/`,
        },
      }}
    />
  );
}
