interface Offer {
  name?: string;
  id?: string | number;
}
export function slugifyOfferName(offer: Offer) {
  const slugifiedName = (offer?.name || '').toLocaleLowerCase().replace(/[^a-z0-9]+/g, '-');
  return `${slugifiedName}-${offer?.id}`;
}

export function addCampaignId(originalPathName: string, campaignTracker?: string | null) {
  if (originalPathName.slice(-1) !== '/') {
    originalPathName = originalPathName + '/';
  }

  if (campaignTracker) {
    return `${originalPathName}?cid=${campaignTracker}`;
  }
  return originalPathName;
}
