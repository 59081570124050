import { useQuery } from '@tanstack/react-query';
import type { StaticOfferAttributeId } from '@dx-ui/gql-types';
import type { TFunction } from 'i18next';
import { useTranslation, Trans } from 'next-i18next';
import set from 'lodash/set';
import cx from 'classnames';
import { Pill } from './pill';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { Spinner } from '@dx-ui/osc-spinner';
import { Pagination } from '@dx-ui/osc-pagination';
import { useReducer, useState } from 'react';
import { slugifyBrand } from './slugify-brand';
import type {
  BrandOfferListingQuery,
  HotelOfferListingQuery,
  OfferListingFragment,
} from '../generated/types';
import { BrandOfferListingDocument, HotelOfferListingDocument } from '../generated/queries';
import { useRouter } from 'next/router';
import { Breadcrumbs } from '@dx-ui/osc-breadcrumbs';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import { offerCategoryMapper } from './offer-category-mapper';
import Icon from '@dx-ui/osc-icon';
import { BrandButton, BrandLink } from '@dx-ui/osc-brand-buttons';

export type CommonOfferListingProps = {
  language: string;
  renderInternalOfferLink?: (offer: OfferListingFragment['offers'][number]) => React.ReactNode;
  assetUrl?: string;
  renderAdditionalComponents?: (
    data: (HotelOfferListingQuery | BrandOfferListingQuery) & { firstImageForOg?: string }
  ) => React.ReactNode;
  hideEmptyAttributes?: boolean;
  sortOffers?: (offers: OfferListingFragment['offers']) => OfferListingFragment['offers'];
  offerCTAClick?: (offer: OfferListingFragment['offers'][0]) => void;
};

interface HotelListing {
  ctyhocn: string;
}

interface BrandListing {
  brandCode: string;
}

function isBrandOffer(props: HotelListing | BrandListing): props is BrandListing {
  return (props as BrandListing).brandCode !== undefined;
}

function isBrandResult(
  data: HotelOfferListingQuery | BrandOfferListingQuery
): data is BrandOfferListingQuery {
  return (data as BrandOfferListingQuery).brandStaticOfferOptions !== undefined;
}

function isGlobalBrand(brandCode = '') {
  return brandCode === 'WW';
}

function formatSearchDetails({
  filterState,
  isGlobalBrandOfferPage,
  offerListingData,
}: {
  filterState: State;
  isGlobalBrandOfferPage: boolean;
  offerListingData: HotelOfferListingQuery | BrandOfferListingQuery | undefined;
}) {
  const brandCode =
    offerListingData && isBrandResult(offerListingData)
      ? offerListingData.brand?.code
      : offerListingData?.hotel?.brand?.code;
  const brands = isGlobalBrandOfferPage ? filterState.brands : brandCode ? [brandCode] : [];
  const mappedCategories = filterState.categories.map((id) => offerCategoryMapper[id]);
  const mappedBrands = brands.map((brandCode) => (isGlobalBrand(brandCode) ? 'HI' : brandCode));
  return [...mappedCategories, ...mappedBrands].join('|');
}

export function OfferListing(props: CommonOfferListingProps & (HotelListing | BrandListing)) {
  const { data, isLoading } = useQuery<HotelOfferListingQuery | BrandOfferListingQuery>({
    queryKey: [
      isBrandOffer(props) ? BrandOfferListingDocument : HotelOfferListingDocument,
      isBrandOffer(props)
        ? {
            brandCode: props.brandCode,
            language: props.language,
          }
        : {
            ctyhocn: props.ctyhocn,
            language: props.language,
          },
    ],
  });
  return isLoading ? (
    <div className="flex items-center justify-center py-8">
      <Spinner size="lg" className="text-primary" />
    </div>
  ) : (
    <OfferListingDisplay {...props} data={data} />
  );
}

export function OfferListingDisplay({
  data,
  renderAdditionalComponents,
  offerCTAClick,
  ...props
}: CommonOfferListingProps &
  (HotelListing | BrandListing) & {
    data?: HotelOfferListingQuery | BrandOfferListingQuery;
  }) {
  const { t } = useTranslation('offers');
  const [filterState, dispatch] = useOfferFilters();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const offerSort = props.sortOffers || ((a) => a);
  const result =
    data && (isBrandResult(data) ? data?.brandStaticOfferOptions : data?.hotelStaticOfferOptions);
  const offers = result?.offers ? filterOffers(offerSort(result?.offers), filterState) : [];
  const firstImageForOg = result?.offers?.[0]?.images?.[0]?.ogImage || '';
  const isGlobalBrandOfferPage = isBrandOffer(props) && isGlobalBrand(props.brandCode);

  function trackFiltersEvent(eventName: 'filters' | 'filters_reset' | 'filters_done') {
    if (window._satellite && window.digitalData) {
      set(window.digitalData, 'click.clickID', eventName);
      if (eventName === 'filters_done' || eventName === 'filters_reset') {
        set(
          window.digitalData,
          'page.attributes.searchDetails',
          eventName === 'filters_done'
            ? formatSearchDetails({
                filterState,
                isGlobalBrandOfferPage,
                offerListingData: data,
              })
            : ''
        );
      }
      window._satellite.track?.('global_click');
    }
  }

  function openFilters() {
    trackFiltersEvent('filters');
    setFiltersOpen(true);
  }

  function closeFilters() {
    trackFiltersEvent('filters_done');
    setFiltersOpen(false);
  }

  function resetFilters() {
    trackFiltersEvent('filters_reset');
    dispatch({ type: 'reset' });
    setFiltersOpen(false);
  }

  return (
    <div>
      {renderAdditionalComponents && renderAdditionalComponents({ ...data, firstImageForOg })}
      <div className="">
        <ListingBreadCrumbs result={data} locale={props.language} t={t} />
        <h1 className="heading-3xl lg:heading-4xl my-4 text-center">
          {data && isBrandResult(data) && !isGlobalBrand((props as BrandListing).brandCode)
            ? t('listingHeadingBrand', { brandName: data?.brand?.name })
            : t('listingHeading')}
        </h1>
        <div className="border-border-alt flex w-full items-center justify-between border-b pb-2">
          <div aria-live="polite">
            {(offers?.length || 0) > 0 && (
              <h2 className="font-bold">
                <span aria-hidden>
                  {t('offersShowing', {
                    firstNum: 1,
                    secondNum: offers?.length || 0,
                    total: offers?.length || 0,
                  })}
                </span>
                <span className="sr-only">
                  {t('offersShowingA11y', {
                    firstNum: 1,
                    secondNum: offers?.length || 0,
                    total: offers?.length || 0,
                  })}
                </span>
              </h2>
            )}
          </div>
          <div className="col-start-2 row-start-1">
            <Dialog
              isOpen={filtersOpen}
              ariaLabel={t('filterButton')}
              onDismiss={closeFilters}
              dialogTrigger={
                <BrandButton
                  className="!btn-base"
                  variant="outline"
                  onClick={openFilters}
                  label={
                    <>
                      <span className="sr-only">{t('offer')}</span>
                      {t('filterButton')}
                      <span className="sr-only">
                        {t('filtersSelected', {
                          count: filterState.categories.length + filterState.brands.length,
                        })}
                      </span>
                    </>
                  }
                />
              }
            >
              <div className="pt-4">
                <FilterModalContent
                  numFilteredOffers={offers?.length || 0}
                  categories={
                    props.hideEmptyAttributes
                      ? result?.attributes.filter((a) => a.total > 0)
                      : result?.attributes
                  }
                  brands={isGlobalBrandOfferPage ? result?.brands : undefined}
                  onDoneClick={closeFilters}
                  resetFilters={resetFilters}
                  filterState={filterState}
                  onCategoryChange={(category, checked) =>
                    dispatch({
                      type: 'categories',
                      value: category.id,
                      checked,
                    })
                  }
                  onBrandChange={(brand, checked) =>
                    dispatch({
                      type: 'brands',
                      value: brand.brandCode,
                      checked,
                    })
                  }
                  assetUrl={props.assetUrl}
                />
              </div>
            </Dialog>
          </div>
        </div>
      </div>
      {offers?.length === 0 && <div className="py-2">{t('noneFoundFilters')}</div>}
      <div className="divide-border-alt space-y-3 divide-y">
        {offers?.map((offer) => {
          const img = offer?.images?.[0];

          return (
            <div
              key={offer.id}
              className={cx('pt-3 md:flex', {
                'gap-3': !!img,
              })}
            >
              {img && (
                <div className="md:w-1/3">
                  {[offer?.images?.[0]].map((img) => {
                    const mdImg = img?.variants?.find((v) => v.size === 'md');
                    return (
                      <img
                        className="image-corner-radius w-full"
                        key={mdImg?.url}
                        src={mdImg?.url || ''}
                        alt={img?.altText || ''}
                      />
                    );
                  })}
                </div>
              )}

              <div className="flex-1 space-y-4">
                <h2 className="text-primary pt-4 text-xl font-bold leading-tight lg:pt-0">
                  {offer.headline}
                </h2>
                <p>{offer.shortDescription}</p>
                {offer?.bookEndFmt && offer?.stayEndFmt && (
                  <div className="flex space-x-2">
                    <Trans t={t} i18nKey="bookBy" values={{ bookByDate: offer?.bookEndFmt }}>
                      <span className="font-bold">Book by date</span>:{offer?.bookEndFmt}
                    </Trans>

                    <div>
                      <Trans t={t} i18nKey="stayBy" values={{ stayByDate: offer?.stayEndFmt }}>
                        <span className="font-bold"> Stay by date:</span> {offer?.stayEndFmt}
                      </Trans>
                    </div>
                  </div>
                )}
                {offer?.attributes?.length ? (
                  <div className="space-x-2">
                    {offer.attributes.map((attribute) => (
                      <Pill key={attribute.id} data-testid="offerListingPill">
                        {attribute.name}
                      </Pill>
                    ))}
                  </div>
                ) : null}
                {offer?.externalLink ? (
                  <BrandLink
                    anchorClassName="inline-block"
                    url={offer.externalLink?.url || ''}
                    isNewWindow={offer.externalLink?.isNewWindow || false}
                    showNewWindowIcon={false}
                    rel="noreferrer"
                    onClick={() => offerCTAClick?.(offer)}
                    label={offer.externalLink?.label || ''}
                    variant="solid"
                  />
                ) : props.renderInternalOfferLink ? (
                  props.renderInternalOfferLink?.(offer)
                ) : (
                  <BrandButton
                    className="inline-block w-full lg:w-auto"
                    label={t('viewDetails')}
                    variant="solid"
                  />
                )}
              </div>
            </div>
          );
        })}
        <div className="py-4">
          <Pagination current={0} total={1} />
        </div>
      </div>
    </div>
  );
}

function FilterModalContent({
  categories,
  brands,
  onDoneClick,
  resetFilters,
  filterState,
  onCategoryChange,
  onBrandChange,
  assetUrl = '',
  numFilteredOffers,
}: {
  categories: OfferListingFragment['attributes'] | undefined;
  brands: OfferListingFragment['brands'] | undefined;
  onDoneClick: () => void;
  resetFilters: () => void;
  filterState: State;
  onCategoryChange: (category: OfferListingFragment['attributes'][0], checked: boolean) => void;
  onBrandChange: (brand: OfferListingFragment['brands'][0], checked: boolean) => void;
  assetUrl?: string;
  numFilteredOffers: number;
}) {
  const { t } = useTranslation('offers');
  const categoriesPanel = categories?.map((cat) => (
    <div key={cat.id} className="border-border mb-2 flex items-center border-b pb-2">
      <label className="label label-inline-reverse w-full">
        <span>{cat.name}</span>
        <input
          className="form-checkbox"
          type="checkbox"
          checked={filterState.categories.includes(cat.id)}
          onChange={(e) => onCategoryChange(cat, e.target.checked)}
        />
      </label>
    </div>
  ));
  return (
    <div>
      <div className="flex items-center">
        <Icon name="filters" className="text-primary me-1" size="lg" />
        <h2 className="text-lg font-bold">{t('filters')}</h2>
      </div>
      {!brands?.length && categories?.length && <div className="py-4">{categoriesPanel}</div>}
      {categories?.length && brands?.length && (
        <Tabs defaultActive="categories">
          <TabList className={cx('border-border-alt flex justify-between border-b')}>
            <TabListButton id="categories">{t('categories')}</TabListButton>
            <TabListButton id="brands">{t('brands')}</TabListButton>
          </TabList>
          <TabPanels className="py-4">
            <TabPanel id="categories">
              <div className="space-y-2">{categoriesPanel}</div>
            </TabPanel>
            <TabPanel id="brands">
              <div>
                {brands?.map((brand) => (
                  <div key={brand.name} className="border-border-alt mb-2 border-b pb-2">
                    <label className="label label-inline-reverse w-full">
                      <span>{brand.name}</span>
                      <img
                        aria-hidden="true"
                        className="size-6"
                        src={`${assetUrl}/modules/assets/svgs/logos/bug/${brand.brandCode}.svg`}
                        alt={brand.name}
                      />
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        checked={filterState.brands.includes(brand.brandCode)}
                        onChange={(e) => onBrandChange(brand, e.target.checked)}
                      />
                    </label>
                  </div>
                ))}
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      <div className="pb-4 text-center font-bold">
        {t('numOffers', { count: numFilteredOffers })}
      </div>

      <div className="flex justify-center gap-2">
        <button className="btn btn-primary-outline" onClick={resetFilters} type="button">
          {t('resetFilters')}
        </button>
        <button className="btn btn-primary" onClick={onDoneClick} type="button">
          {t('done')}
        </button>
      </div>
    </div>
  );
}

function filterOffers(offers: OfferListingFragment['offers'] | undefined, filterState: State) {
  return offers?.filter((offer) => {
    if (!filterState.categories.length && !filterState.brands.length) {
      return true;
    }
    const hasBrands =
      filterState.brands.length === 0 ||
      offer.brands.some((brand) => filterState.brands.includes(brand));
    const hasAttributes =
      filterState.categories.length === 0 ||
      offer.attributes.some((attr) => filterState.categories.includes(attr.id));
    return hasBrands && hasAttributes;
  });
}

interface State {
  brands: string[];
  categories: StaticOfferAttributeId[];
}

interface Action {
  type: 'brands' | 'categories' | 'reset';
  value?: string;
  checked?: boolean;
}

function reducer(state: State, action: Action): State {
  if (action.type === 'reset') {
    return {
      brands: [],
      categories: [],
    };
  }
  const actionableState = state[action.type];
  let changedState = [...actionableState];
  if (action.checked && action.value) {
    changedState.push(action.value);
  }
  if (!action.checked && action.value) {
    changedState = changedState.filter((item) => item !== action.value);
  }

  return {
    ...state,
    [action.type]: changedState,
  };
}

function useOfferFilters() {
  return useReducer(reducer, {
    categories: [],
    brands: [],
  });
}

function ListingBreadCrumbs({
  result,
  locale,
  t,
}: {
  result: BrandOfferListingQuery | HotelOfferListingQuery | undefined;
  locale: string;
  t: TFunction<'offers'>;
}) {
  const { asPath } = useRouter();

  if (!result) {
    return null;
  }

  const allOffersItem = {
    name: t('breadCrumbs.allOffers'),
    uri: `/${locale}/offers/`,
  };
  if (isBrandResult(result)) {
    if (isGlobalBrand(result?.brand?.code)) {
      return (
        <Breadcrumbs
          breadcrumbs={[{ name: t('breadCrumbs.allOffers'), uri: `/${locale}${asPath}` }]}
        />
      );
    }
    return (
      <Breadcrumbs
        breadcrumbs={[
          allOffersItem,
          {
            name: t('breadCrumbs.brandOffers', {
              brand: result?.brand?.name,
            }),
            uri: `/${locale}${asPath}`,
          },
        ]}
      />
    );
  } else {
    // hotel listing page
    return (
      <Breadcrumbs
        breadcrumbs={[
          allOffersItem,
          {
            uri: `/${locale}/offers/${slugifyBrand(result?.hotel?.brand?.name)}/`,
            name: t('breadCrumbs.brandOffers', {
              brand: result?.hotel?.brand?.name,
            }),
          },
          {
            name: t('breadCrumbs.hotelOffers', {
              hotelName: result?.hotel?.name,
            }),
            uri: `/${locale}${asPath}`,
          },
        ]}
      />
    );
  }
}
