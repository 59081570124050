import type { Link } from '@dx-ui/osc-link';
import { Footer } from '@dx-ui/osc-footer';
import { useNavigationQuery } from '../generated/react-query';

export function OffersFooter({ brandCode, language }: { brandCode: string; language: string }) {
  const { data } = useNavigationQuery({ brandCode, language: language || 'en' });
  return (
    <Footer
      links={data?.brand?.footer?.sections?.reduce((prev, curr) => {
        return prev.concat(curr.links as unknown as Link[]);
      }, [] as Link[])}
      contactPhoneLink={{
        url: 'tel:+1-800-446-6677',
        label: '+1-800-4honors',
      }}
      customerSupportLink={{
        url: 'https://help.hilton.com/s/',
        isNewWindow: true,
      }}
    />
  );
}
