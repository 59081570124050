import type { StaticOfferAttributeId } from '@dx-ui/gql-types';

export const offerCategoryMapper: Record<StaticOfferAttributeId, string> = {
  allInclusive: 'INC',
  beach: 'BCH',
  celebration: 'CBN',
  creditCard: 'CC',
  dining: 'DNG',
  discount: 'DSC',
  earnPoints: 'EPT',
  freeBreakfast: 'BFST',
  golf: 'GOL',
  honors: 'HMBR',
  hotelCredit: 'HTLC',
  kidsFamily: 'KAF',
  parking: 'PKG',
  pet: 'PET',
  resort: 'RSRT',
  seasonal: 'SNL',
  spa: 'SPA',
} as const;
