import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const AllBrandsDocument = {
  operationName: 'brands',
  operationString: `query brands($language: String!) {
  brands(language: $language, filter: {isHotelBrand: true}) {
    code
    altSlugs
    canonicalSlug
    isHotelBrand
    formalName
    url
    name
  }
}`,
  originalOpName: 'allBrands',
};
export function useAllBrandsQuery<TData = Types.AllBrandsQuery, TError = DXError>(
  variables: Types.AllBrandsQueryVariables,
  options?: Omit<UseQueryOptions<Types.AllBrandsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.AllBrandsQuery, TError, TData>({
    queryKey: [AllBrandsDocument, variables],
    ...options,
  });
}
export function serverSideAllBrandsQuery(
  queryClient: QueryClient,
  variables: Types.AllBrandsQueryVariables,
  options?: FetchQueryOptions<Types.AllBrandsQuery>
) {
  return queryClient.fetchQuery<Types.AllBrandsQuery>({
    queryKey: [AllBrandsDocument, variables],
    ...options,
  });
}

export const BrandDocument = {
  operationName: 'brand',
  operationString: `query brand($language: String!, $brandCode: String!) {
  brand(language: $language, brandCode: $brandCode) {
    name
    formalName
    code
    url
  }
}`,
  originalOpName: 'brand',
};
export function useBrandQuery<TData = Types.BrandQuery, TError = DXError>(
  variables: Types.BrandQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandQuery, TError, TData>({
    queryKey: [BrandDocument, variables],
    ...options,
  });
}
export function serverSideBrandQuery(
  queryClient: QueryClient,
  variables: Types.BrandQueryVariables,
  options?: FetchQueryOptions<Types.BrandQuery>
) {
  return queryClient.fetchQuery<Types.BrandQuery>({
    queryKey: [BrandDocument, variables],
    ...options,
  });
}

export const FeatureConfigsDocument = {
  operationName: 'featureConfigs',
  operationString: `query featureConfigs {
  featureConfigs(names: ["OhwBrandConfig"]) {
    config
    name
  }
}`,
  originalOpName: 'featureConfigs',
};
export function useFeatureConfigsQuery<TData = Types.FeatureConfigsQuery, TError = DXError>(
  variables?: Types.FeatureConfigsQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureConfigsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureConfigsQuery, TError, TData>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}
export function serverSideFeatureConfigsQuery(
  queryClient: QueryClient,
  variables?: Types.FeatureConfigsQueryVariables,
  options?: FetchQueryOptions<Types.FeatureConfigsQuery>
) {
  return queryClient.fetchQuery<Types.FeatureConfigsQuery>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}

export const FeatureTogglesDocument = {
  operationName: 'featureToggles',
  operationString: `query featureToggles($names: [String]!) {
  featureToggles(names: $names) {
    enabled
    name
  }
}`,
  originalOpName: 'featureToggles',
};
export function useFeatureTogglesQuery<TData = Types.FeatureTogglesQuery, TError = DXError>(
  variables: Types.FeatureTogglesQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureTogglesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureTogglesQuery, TError, TData>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}
export function serverSideFeatureTogglesQuery(
  queryClient: QueryClient,
  variables: Types.FeatureTogglesQueryVariables,
  options?: FetchQueryOptions<Types.FeatureTogglesQuery>
) {
  return queryClient.fetchQuery<Types.FeatureTogglesQuery>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}

export const NavigationDocument = {
  operationName: 'brand',
  operationString: `query brand($brandCode: String!, $language: String!) {
  brand(brandCode: $brandCode, language: $language) {
    header {
      sections {
        links {
          adaDescription
          label
          url
        }
      }
    }
    footer {
      sections {
        links {
          adaDescription
          label
          url
          isNewWindow
        }
      }
    }
  }
}`,
  originalOpName: 'navigation',
};
export function useNavigationQuery<TData = Types.NavigationQuery, TError = DXError>(
  variables: Types.NavigationQueryVariables,
  options?: Omit<UseQueryOptions<Types.NavigationQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.NavigationQuery, TError, TData>({
    queryKey: [NavigationDocument, variables],
    ...options,
  });
}
export function serverSideNavigationQuery(
  queryClient: QueryClient,
  variables: Types.NavigationQueryVariables,
  options?: FetchQueryOptions<Types.NavigationQuery>
) {
  return queryClient.fetchQuery<Types.NavigationQuery>({
    queryKey: [NavigationDocument, variables],
    ...options,
  });
}
